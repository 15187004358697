
[data-v-4a8b79a5] .green_dot {
  background: #8DC63F;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-4a8b79a5] .red_dot {
  background: #D9534F;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-4a8b79a5] .blue_dot {
  background: #2B295E;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-4a8b79a5] .yellow_dot {
  background: #F8C41C;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-4a8b79a5] .color-cb {
  color: #70A7DB !important;
}
[data-v-4a8b79a5] .color-sg {
  color: #6A6A6A !important;
}
[data-v-4a8b79a5] .color-rf {
  color: #C9338F !important;
}
.form-group-pg[data-v-4a8b79a5] {
  position: relative;
  margin-bottom: 10px;
}
label.input-label[data-v-4a8b79a5] {
  float: left;
  width: 100%;
  padding: 10px 0;
  margin: 0 !important;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  color: #2B295E;
}
label.input-label.label-blank[data-v-4a8b79a5] {
  margin-bottom: 18.67px;
}
.iconClipboardGetAddress[data-v-4a8b79a5] {
  position: absolute;
  bottom: 12px;
  width: 15px;
  cursor: pointer;
  right: 10px;
  display: initial;
}
.hide-icon[data-v-4a8b79a5] {
  display: none;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
.hide-icon[data-v-4a8b79a5] {
    position: absolute;
    bottom: 12px;
    width: 15px;
    cursor: pointer;
    right: 10px;
    display: initial;
}
}
