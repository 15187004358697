
[data-v-32cce968] .green_dot {
  background: #8DC63F;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-32cce968] .red_dot {
  background: #D9534F;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-32cce968] .blue_dot {
  background: #2B295E;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-32cce968] .yellow_dot {
  background: #F8C41C;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
[data-v-32cce968] .color-cb {
  color: #70A7DB !important;
}
[data-v-32cce968] .color-sg {
  color: #6A6A6A !important;
}
[data-v-32cce968] .color-rf {
  color: #C9338F !important;
}
.form-group-pg[data-v-32cce968] {
  margin-bottom: 10px;
  position: relative;
}
.form-group-pg .select-wrap[data-v-32cce968] {
  margin: 0;
  padding: 0;
}
.form-group-pg.ml-1[data-v-32cce968] {
  margin-left: -1px;
}
label.input-label[data-v-32cce968] {
  color: #2B295E;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  width: 100%;
  float: left;
  padding: 10px 0px;
  margin: 0px !important;
}
.form-group-pg .select-wrap[data-v-32cce968]:before {
  content: "";
  display: block;
  height: 8px;
  left: auto;
  margin: auto;
  position: absolute;
  right: 10px;
  bottom: 15px;
  width: 14px;
  pointer-events: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAOCAYAAAAi2ky3AAAAAXNSR0IArs4c6QAAAS1JREFUKBVjYKAmSEubyerg2yBCqpm2npWiIL0gfYwgxrm7d1Yx/P+vwcjB6Hh6W/cLYgw09SqV+P/j/34GRsYbRsoqYcxM/BrLgRqDgViE4Q+Dj7Sm9dpnt499wWcY3BAGBg2gOo0X796pMzEyMoEM+g3VqMHwg+GArXuVJC6DwHJANUB5kCEg8BtkBiOIZeZcFvDv/79VQCbEvwyMNzlYWR0P72x7DpKHAZAhP37/3v+f4b86VOw3EyNT2Km9XRvABoEECRmGzxCQfrhB+AwDyeFyCUgOBFAMAglguIyR4RZI/P9/BjUQDQRw70C4EBLDIJAwumFIGrAaApJnRlIEZz69f/SGjJLNZWCggpIFTA1OQ0AasboIZiKSyxhgsQOTI5kGGQbCJGskVwMA5JeQhkiGHLYAAAAASUVORK5CYII=);
  background-size: contain;
  background-repeat: no-repeat;
}
.form-group-pg select.select[data-v-32cce968] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  background: rgba(255, 255, 255, 0);
  background: -webkit-linear-gradient(rgba(255, 255, 255, 0), #e6f2ff);
  background: -o-linear-gradient(rgba(255, 255, 255, 0), #e6f2ff);
  background: -moz-linear-gradient(rgba(255, 255, 255, 0), #e6f2ff);
  background: linear-gradient(180deg, #FFFFFF 0%, #F3F3F3 100%);
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  -ms-border-radius: 3px !important;
  -o-border-radius: 3px !important;
  border-radius: 3px;
  border: 1px solid #C7C7C7;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  height: 40px;
  padding: 4px 35px 4px 10px;
  width: 100%;
  color: #2B295E;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  box-sizing: border-box;
}
.form-group-pg select.select.italic[data-v-32cce968] {
  font-style: italic;
}
.form-group-pg select.select.right[data-v-32cce968] {
  border-radius: 0px 3px 3px 0px !important;
}
.form-group-pg select.select.left[data-v-32cce968] {
  border-radius: 3px 0px 0px 3px !important;
}
.form-group-pg select.select.center[data-v-32cce968] {
  border-radius: 0px 0px 0px 0px !important;
}
.form-group-pg select.select.error[data-v-32cce968] {
  border-color: #D0021B;
}
.form-group-pg select.select.disabled[data-v-32cce968] {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
.form-group-pg select.select.grey[data-v-32cce968] {
  color: #c7c7c7;
}
.form-group-pg select.select[data-v-32cce968]:focus {
  outline: none !important;
}
.form-group-pg select.select[data-v-32cce968]::-ms-expand {
  display: none !important;
}
.error-message[data-v-32cce968] {
  height: 35px;
  width: 100px;
  border: 1px solid #D0021B;
  border-radius: 3px;
  background-color: #D0021B;
  -webkit-box-shadow: 0 -2px 6px 0 rgba(208, 2, 27, 0.3);
  box-shadow: 0 -2px 6px 0 rgba(208, 2, 27, 0.3);
  color: white;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  text-align: center;
  padding: 9px 5px 8px 5px;
  position: absolute;
  top: -7px;
  left: 38%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 125%;
}
.error-message[data-v-32cce968]::after {
  height: 11px;
  width: 11px;
  background: #d0021b;
  content: '.';
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 28px;
  left: calc(50% - 5.5px);
  color: transparent;
}
