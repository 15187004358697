
.cover-page[data-v-4506b7d0]{
  background: rgba(255, 255, 255, 0.5);
  position:fixed;
  padding: 0px;
  margin: 0px;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999 !important;
}
.loading-pic[data-v-4506b7d0]{
  position: absolute; 
  top: 50%; 
  left: 50%; 
  transform: translate(-50%,-50%);
}
