
.error-message {
    width: 100%;
    border-radius: 3px;
    background-color: rgba(208, 2, 27, 0.1);
    color: #d0021b;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 19px;
    float: left;
    display: inline-block;
    font-weight: normal;
    margin-bottom: 12px;
    padding: 0px;
}
.error-message p{
    margin: 0px;
    padding: 10px 15px;
}
